// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "G_vP d_gv d_cs";
export var heroHeaderCenter = "G_gw d_gw d_cs d_dw";
export var heroHeaderRight = "G_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "G_vQ d_gr d_cw";
export var heroParagraphCenter = "G_gs d_gs d_cw d_dw";
export var heroParagraphRight = "G_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "G_vR d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "G_vS d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "G_vT d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "G_vV d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "G_vW d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "G_tP C_tP";
export var heroExceptionNormal = "G_tQ C_tQ";
export var heroExceptionLarge = "G_tR C_tR";
export var Title1Small = "G_tB C_tB C_s5 C_s6";
export var Title1Normal = "G_tC C_tC C_s5 C_s7";
export var Title1Large = "G_tD C_tD C_s5 C_s8";
export var BodySmall = "G_sY C_sY C_s5 C_tp";
export var BodyNormal = "G_sZ C_sZ C_s5 C_tq";
export var BodyLarge = "G_s0 C_s0 C_s5 C_tr";