// extracted by mini-css-extract-plugin
export var tileContent = "K_wg d_w d_H d_Z";
export var teamTextLeft = "K_wh d_dv";
export var teamTextCenter = "K_wj d_dw";
export var teamTextRight = "K_wk d_dx";
export var alignLeft = "K_rd d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "K_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "K_rf d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "K_wl d_dW";
export var teamContainerFull = "K_wm d_dT";
export var teamRowWrapper = "K_wn d_cc";
export var teamTileWrapper = "K_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "K_wp d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "K_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "K_j5 d_j5 d_Z";
export var teamHoverNoGutters = "K_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "K_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "K_wq d_j7";
export var teamInfoWrapperRound = "K_j7 d_j7";
export var teamInfoWrapper = "K_wr d_j8 d_0";
export var teamInfoWrapperNoGutters = "K_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "K_jZ d_jZ";
export var teamImgWrapperAlt = "K_j0 d_j0";
export var teamImgWrapperNoGutters = "K_kb d_kb";
export var teamHeader = "K_ws d_cw";
export var teamHeaderAlt = "K_wt d_cw";
export var teamHeaderNoGutters = "K_wv d_cw d_cD";