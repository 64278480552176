// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "H_vP d_gv d_cs";
export var heroHeaderCenter = "H_gw d_gw d_cs d_dw";
export var heroHeaderRight = "H_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "H_vQ d_gr d_cw";
export var heroParagraphCenter = "H_gs d_gs d_cw d_dw";
export var heroParagraphRight = "H_gt d_gt d_cw d_dx";
export var heroSliderBtnWrapperLeft = "H_vX d_d2 d_d1 d_w d_bz d_bG d_bJ";
export var heroSliderBtnWrapperCenter = "H_vY d_d3 d_d1 d_w d_bz d_bD d_bJ";
export var heroSliderBtnWrapperRight = "H_vZ d_d4 d_d1 d_w d_bz d_bH d_bJ";
export var btnWrapper = "H_d2";
export var wrapper = "H_r2 d_bz d_bP d_w d_H";
export var background = "H_ng d_bd d_0 d_8 d_7 d_4 d_9";
export var frameWrapper = "H_v0 d_w d_H d_Z d_4 d_7 d_bk d_bf d_bv";
export var videoFrame = "H_v1 d_0";
export var videoOverlay = "H_v2 d_w d_H d_0 d_4";
export var arrow = "H_kG d_0 d_n";
export var arrowLeft = "H_v3 H_kG d_0 d_n";
export var arrowRight = "H_v4 H_kG d_0 d_n";
export var buttonsWrapper = "H_v5 d_bz d_bP";
export var dotsWrapper = "H_v6 d_bz d_bP d_bD d_0";
export var dot = "H_v7 d_bx d_dB";
export var dotActive = "H_v8 H_v7 d_bx d_dB";
export var heroSliderBackgroundImage = "H_gz d_w d_H d_bR d_bk";
export var loadingWrapper = "H_v9 d_bd d_0 d_8 d_7 d_4 d_9";
export var topAlt = "H_gC d_gC d_gB d_bz d_bP d_w";
export var top = "H_gD d_gD d_gB d_bz d_bP d_w";
export var bottomAlt = "H_gG d_gG d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var bottom = "H_gH d_gH d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var heroExceptionSmall = "H_tP C_tP";
export var heroExceptionNormal = "H_tQ C_tQ";
export var heroExceptionLarge = "H_tR C_tR";