// extracted by mini-css-extract-plugin
export var carouselContainer = "T_sm d_w d_H d_bf d_Z";
export var carouselContainerCards = "T_xG T_sm d_w d_H d_bf d_Z";
export var carouselContainerSides = "T_xH d_w d_H d_Z";
export var prevCarouselItem = "T_xJ d_w d_H d_0 d_k";
export var prevCarouselItemL = "T_xK T_xJ d_w d_H d_0 d_k";
export var moveInFromLeft = "T_xL";
export var prevCarouselItemR = "T_xM T_xJ d_w d_H d_0 d_k";
export var moveInFromRight = "T_xN";
export var selectedCarouselItem = "T_xP d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "T_xQ T_xP d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "T_xR T_xP d_w d_H d_Z d_bf";
export var nextCarouselItem = "T_xS d_w d_H d_0 d_k";
export var nextCarouselItemL = "T_xT T_xS d_w d_H d_0 d_k";
export var nextCarouselItemR = "T_xV T_xS d_w d_H d_0 d_k";
export var arrowContainer = "T_xW d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "T_xX T_xW d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "T_xY T_xX T_xW d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "T_xZ T_xW d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "T_x0";
export var nextArrowContainerHidden = "T_x1 T_xZ T_xW d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "T_kG d_0";
export var prevArrow = "T_x2 T_kG d_0";
export var nextArrow = "T_x3 T_kG d_0";
export var carouselIndicatorContainer = "T_x4 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "T_x5 d_w d_bz d_bF";
export var carouselText = "T_x6 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "T_x7 T_x6 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "T_x8 d_b7";
export var carouselIndicator = "T_x9 T_x8 d_b7";
export var carouselIndicatorSelected = "T_yb T_x8 d_b7";
export var arrowsContainerTopRight = "T_yc d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "T_yd d_0 d_bl d_bC";
export var arrowsContainerSides = "T_yf d_0 d_bl d_bC";
export var smallArrowsBase = "T_yg d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "T_yh T_yg d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "T_yj T_yh T_yg d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "T_yk T_yg d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "T_yl T_yk T_yg d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "T_n9";
export var iconSmall = "T_ym";
export var multipleWrapper = "T_yn d_bC d_bF d_bf";
export var multipleImage = "T_yp d_bC";
export var sidesPrevContainer = "T_yq T_yh T_yg d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "T_yr T_yh T_yg d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";