// extracted by mini-css-extract-plugin
export var alignLeft = "J_rd d_bG";
export var alignCenter = "J_bP d_bD";
export var alignRight = "J_rf d_bH";
export var textAlignLeft = "J_sP";
export var textAlignCenter = "J_sQ";
export var textAlignRight = "J_sR";
export var hoursInnerWrapperAlt = "J_wb d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "J_wc d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "J_wd d_dw";
export var titleMargin = "J_sV d_cw";
export var hoursInnerInnerWrapper = "J_wf d_cz";