// extracted by mini-css-extract-plugin
export var alignLeft = "L_rd d_fp d_bG d_dv";
export var alignCenter = "L_bP d_fq d_bD d_dw";
export var alignRight = "L_rf d_fr d_bH d_dx";
export var menuContainer = "L_ww d_dW";
export var menuContainerFull = "L_wx d_dT";
export var menuMainHeader = "L_jC d_jC d_w d_c3";
export var menuComponentWrapper = "L_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "L_jH d_jH d_cy";
export var menuComponentText = "L_jD d_jD d_cn";
export var menuComponentTextDesign2 = "L_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "L_jJ d_jJ d_w d_Z";