// extracted by mini-css-extract-plugin
export var alignLeft = "y_rd d_bG";
export var alignCenter = "y_bP d_bD";
export var alignRight = "y_rf d_bH";
export var textAlignLeft = "y_sP";
export var textAlignCenter = "y_sQ";
export var textAlignRight = "y_sR";
export var embedInnerWrapperDesign1 = "y_sS d_bM";
export var embed = "y_sT d_b1";
export var titleMargin = "y_sV d_cw d_dw";
export var subtitleMargin = "y_sW d_cs d_dw";
export var paragraphMargin = "y_sX d_cw d_dw";
export var SubtitleSmall = "y_pd C_pd C_s5 C_th";
export var SubtitleNormal = "y_pf C_pf C_s5 C_tj";
export var SubtitleLarge = "y_pg C_pg C_s5 C_tk";
export var BodySmall = "y_sY C_sY C_s5 C_tp";
export var BodyNormal = "y_sZ C_sZ C_s5 C_tq";
export var BodyLarge = "y_s0 C_s0 C_s5 C_tr";